<script setup lang="ts">
import { Image as DatocmsImage, type ResponsiveImageType } from "vue-datocms";
import type { EventRecord, ImageFileField } from "~/graphql/datocms/generated";

defineProps<{ event: EventRecord }>();

const localePath = useLocalePath();
</script>

<template>
  <div class="stretched-link-card h-full rounded-sm bg-white p-lg">
    <div class="mb-lg">
      <DatocmsImage
        v-if="event.teaserImage as ImageFileField" :data="event.teaserImage.responsiveImage as ResponsiveImageType"
        :fade-in-duration="0" :use-placeholder="false"
      />
    </div>

    <div class="flex flex-col justify-between">
      <div class="h-32">
        <h5 class="mb-md">
          {{ event.title }}
        </h5>

        <div class="line-clamp-3" v-html="event.teaserText" />
      </div>

      <NuxtLink :to="localePath({ name: 'events-slug', params: { slug: event.slug } })" class="text-primary underline">
        {{ event.teaserLinkText }}
      </NuxtLink>
    </div>
  </div>
</template>

<style scoped>

</style>

<script lang="ts" setup>
import type { EventRecord, UpcomingEventsSectionRecord } from "~/graphql/datocms/generated";

const props = defineProps<{ data: UpcomingEventsSectionRecord, currentPageId: string }>();

const localePath = useLocalePath();

const { locale } = useI18n();
const currentDateAsString = (new Date()).toLocaleDateString(locale.value, { day: "2-digit", month: "2-digit", year: "numeric" });

const { data: eventsData } = await useFetch("/api/events", {
  query: { pageSize: props.data.itemCount + 1, page: 1, endDate: currentDateAsString, locale: locale },
});

const filteredEvents = computed(() => {
  return eventsData.value?.allEvents?.filter(e => e.id !== props.currentPageId).slice(0, props.data.itemCount);
});
</script>

<template>
  <div>
    <div class="flex flex-col items-center">
      <h2 v-if="filteredEvents?.length" class="mb-3xl">
        {{ data.title }}
      </h2>

      <div class="mb-3xl w-full xl:w-4/5">
        <div v-show="filteredEvents?.length" class="grid grid-cols-1 gap-lg md:grid-cols-2 md:gap-md lg:grid-cols-3">
          <EventCard v-for="event in filteredEvents" :key="event.id" :event="event as EventRecord" />
        </div>
      </div>

      <UButton variant="outline" color="primary" size="md" :to="localePath(`/${data.linkPage.slug}`)">
        {{ data.linkText }}
      </UButton>
    </div>
  </div>
</template>

<style scoped>

</style>
